import { showNewMessage } from "@intercom/messenger-js-sdk";
import { useWebTranslation } from "translations";
import { Button } from "ui-kit/Button";
import styles from "./ContentfulC2A.module.css";

export const MessageButton = () => {
  const { t } = useWebTranslation();

  return (
    <Button
      variant="outlined"
      textColor="blue300"
      title={t("c2a.send_message")}
      icon="chat-lines"
      iconFirst
      onPress={() => showNewMessage("")}
      className={styles.button}
    />
  );
};
